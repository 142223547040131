import React from "react";
import styles from "./style.module.scss";
import { SectionTitle, Text, Anchor } from "../../styledComponent";

const PromotionCard = (props) => {
  return (
    <div className={styles.promotionCard}>
      <SectionTitle>{props.title}</SectionTitle>
      <Text>{props.description}</Text>
      <Anchor href={props.link}>{props.cta}</Anchor>
    </div>
  );
};

export default PromotionCard;
