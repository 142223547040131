export const offersData = [
  {
    id: 1,
    title: "Happy Hour Deal",
    items: [
      {
        id: 1,
        title: "APPETIZERS",
        description: "50% OFF",
      },
      {
        id: 2,
        title: "TANDOORI",
        description:
          "$10.99- Choose any 1 item Chicken Tikka (4 Pieces of Chicken) Tandoori Chicken (2 pieces) Kabab (2 pieces -chicken/beef)",
      },
      {
        id: 3,
        title: "CURRY",
        description: "$5 Dollar off",
      },
      {
        id: 4,
        title: "Biryani",
        description: "20% Off",
      },
    ],
  },
  {
    id: 2,
    title: "Every Day Deal",
    items: [
      {
        id: 2,
        title: "TUESDAY (CURRY DEAL)",
        description: "20% Off",
      },
      {
        id: 3,
        title: "WEDNESDAY (TANDOORI NIGHT)",
        description: "$5 Dollar off",
      },
      {
        id: 4,
        title: "THURSDAY (ALL DAY NOODLES)",
        description: "20% Off",
      },
      {
        id: 5,
        title: "FRIDAY (APPETIZER DEAL)",
        description: "BUY 1 APPETIZER GET 1 50% OFF",
      },
      {
        id: 6,
        title: "SATURDAY (ALL DAY BIRYANI)",
        description: "20% Off",
      },
      {
        id: 7,
        title: "SUNDAY (BUTTER CHICKEN DEAL)",
        description: "BUY 1 BUTTER CHICKEN GET 1 50% OFF",
      },
      {
        id: 8,
        title: "SUNDAY (LAZY BREAKFAST DEAL)",
        description: "50% OFF ON CHOLEY BHATUREY",
      },
    ],
  },
];
