import React from "react";
import { easeInOut, motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { SectionTitle } from "../../components/styledComponent";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import { LineDivider } from "../../components/ui";
import { offersData } from "../../utils/data";
import Appetizers from "../../assets/appetizers.webp";
import Biryani from "../../assets/biryani.webp";
import Curry from "../../assets/curry.webp";
import HappyHour from "../../assets/happyHour.webp";
import Sunday from "../../assets/sunday.webp";
import SundayLazy from "../../assets/sundayLazy.webp";
import Thursday from "../../assets/thursday.webp";
import Wednesday from "../../assets/wednesday.webp";

const Offers = () => {
  return (
    <>
      <Helmet>
        <title>CATERING - Billoos Restaurant Calgary</title>
      </Helmet>
      <div className={styles.contactPage}>
        {/* Contact section start */}
        <section className={styles.contactSection}>
          <Container
            fluid
            className={`sectionContainer ${styles.reserveInnerSection}`}
          >
            <Row>
              <Col lg={12} className={styles.contactBlock}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.5, easings: easeInOut }}
                >
                  <p className={styles.smallTxt}>Offers</p>
                  <div className={styles.offersContainer}>
                    <div>
                      <SectionTitle>Happy Hour Deal</SectionTitle>
                      <LineDivider />
                      <p className={styles.offerNote}>
                        *From 1:00 PM to 4:00 PM (Tuesday to Saturday)
                      </p>
                      {/* <ul>
                        {offersData[0]?.items?.map((item) => (
                          <li key={item.id}>
                            <CouponBox
                              title={item.title}
                              description={item.description}
                            />
                          </li>
                        ))}
                      </ul> */}
                      <div className={styles.offersImageContainer}>
                        <div className={styles.offersImage}>
                          <img src={HappyHour} alt="happy hour" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <SectionTitle>Every Day Deal</SectionTitle>
                      <LineDivider />
                      <div className={styles.offersImageContainer}>
                        <div className={styles.offersImage}>
                          {/* <h5>Curry</h5> */}
                          <img src={Curry} alt="curry" />
                        </div>
                        <div className={styles.offersImage}>
                          {/* <h5>Curry</h5> */}
                          <img src={Wednesday} alt="wednesday" />
                        </div>
                        <div className={styles.offersImage}>
                          {/* <h5>Appetizers</h5> */}
                          <img src={Thursday} alt="appetizers" />
                        </div>
                        <div className={styles.offersImage}>
                          {/* <h5>Appetizers</h5> */}
                          <img src={Appetizers} alt="appetizers" />
                        </div>
                        <div className={styles.offersImage}>
                          {/* <h5>Biryani</h5> */}
                          <img src={Biryani} alt="biryani" />
                        </div>
                        <div className={styles.offersImage}>
                          {/* <h5>Biryani</h5> */}
                          <img src={Sunday} alt="biryani" />
                        </div>
                        <div className={styles.offersImage}>
                          <img src={SundayLazy} alt="biryani" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.offerTable}>
                    <div>
                      <h2>Happy Hour Deal</h2>
                      <LineDivider />
                      <p className={styles.offerNote}>
                        *From 1:00 PM to 4:00 PM (Tuesday to Saturday)
                      </p>
                      <ul>
                        {offersData[0]?.items?.map((item) => (
                          <li key={item.id}>
                            <h5>{item.title}</h5>
                            <p>{item.description}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h2>Every Day Deal</h2>
                      <LineDivider />
                      <ul>
                        {offersData[1]?.items?.map((item) => (
                          <li key={item.id}>
                            <h5>{item.title}</h5>
                            <p>{item.description}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Offers;
