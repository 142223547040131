import { routes } from "./routes";

export const navMenuItems = [
  {
    name: "Home",
    link: routes.HOME,
  },
  {
    name: "Menu",
    link: routes.TAKE_OUT_MENU,
  },
  {
    name: "Catering",
    link: routes.CATERING,
  },
  {
    name: "About us",
    link: routes.ABOUT,
  },
  {
    name: "Offers",
    link: routes.OFFERS,
  },
  {
    name: "Contact us",
    link: routes.CONTACT,
  },
];

export const mobileNavMenuItems = [
  {
    name: "Home",
    link: routes.HOME,
  },
  {
    name: "Menu",
    link: routes.TAKE_OUT_MENU,
  },
  {
    name: "Catering",
    link: routes.CATERING,
  },
  {
    name: "About us",
    link: routes.ABOUT,
  },
  {
    name: "Offers",
    link: routes.OFFERS,
  },
  {
    name: "Billoos Corporate",
    link: routes.BILLOOS_CORPORATE,
  },
  {
    name: "Contact us",
    link: routes.CONTACT,
  },
];

export const footerNavMenuItems = [
  {
    name: "Home",
    link: routes.HOME,
  },
  {
    name: "Menu",
    link: routes.TAKE_OUT_MENU,
  },
  {
    name: "Catering",
    link: routes.CATERING,
  },
  {
    name: "About us",
    link: routes.ABOUT,
  },
  {
    name: "Offers",
    link: routes.OFFERS,
  },
  {
    name: "Contact us",
    link: routes.CONTACT,
  },
];

export const promotionSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  autoplay: true,
};

export const workingHours = [
  {
    day: "Monday",
    timing: "Closed",
  },
  {
    day: "Tue - Sun",
    timing: "12:00 PM - 09:30 PM",
  },
];

export const priceListData = [
  {
    title: "NOV-VEG CURRY",
    priceData: [
      {
        title: "BUTTER CHICKEN",
        price: "16",
      },
      {
        title: "CURRY - CHICKEN /BEEF",
        price: "16",
      },
      {
        title: "CURRY - GOAT/LAMB /SHRIMP",
        price: "18",
      },
      {
        title: "KARAHI - CHICKEN/BEEF",
        price: "16",
      },
      {
        title: "KAHARI - GOAT/LAMB /SHRIMP",
        price: "18",
      },
      {
        title: "KORMA - CHICKEN/BEEF",
        price: "16",
      },
      {
        title: "KORMA - GOAT/LAMB /SHRIMP",
        price: "18",
      },
      {
        title: "CHICKEN TIKKA MASALA",
        price: "16",
      },
      {
        title: "BLACK PEPPER CHICKEN",
        price: "16",
      },
      {
        title: "BILLOO'S CREAMY CHICKEN",
        price: "17",
      },
      {
        title: "VINDALOO- CHK/BEEF/GOAT/LAMB",
        price: "18",
      },
      {
        title: "VINDALOO- CHK/BEEF/GOAT/LAMB",
        price: "18",
      },
    ],
  },
  {
    title: "VEGETARIAN CURRY",
    priceData: [
      {
        title: "DAL TADKA",
        price: "13",
      },
      {
        title: "DAL MAKHANI",
        price: "14",
      },
      {
        title: "CHANNA MASALA",
        price: "14",
      },
      {
        title: "PANEER BUTTER MAKHANI",
        price: "15",
      },
      {
        title: "PANEER BUTTER MASALA",
        price: "15",
      },
      {
        title: "SHAHI PANEER",
        price: "15",
      },
      {
        title: "KARAHI PANEER",
        price: "15",
      },
      {
        title: "PALAK PANEER",
        price: "15",
      },
      {
        title: "MIX VEGETABLE",
        price: "14",
      },
      {
        title: "GOBHI TAK TAK",
        price: "15",
      },
      {
        title: "VEG BUTTER CHICKEN",
        price: "16",
      },
      {
        title: "SOYA CHAAP MASALA",
        price: "16",
      },
      {
        title: "MALAI KOFTA",
        price: "16",
      },
    ],
  },
  {
    title: "INDO CHINESE SPECIAL",
    priceData: [
      {
        title: "CHILLI CHICKEN",
        price: "15",
      },
      {
        title: "HAKKA NOODEL (Veg)",
        price: "12",
      },
      {
        title: "HAKKA NOODEL (Non-Veg)",
        price: "14",
      },
      {
        title: "VEG MANCHURIAN",
        price: "14",
      },
      {
        title: "CHIIL GARLIC NOODEL (Veg)",
        price: "13",
      },
      {
        title: "CHIIL GARLIC NOODEL (Non-Veg)",
        price: "15",
      },
      {
        title: "FRIED RICE (Veg)",
        price: "10",
      },
      {
        title: "FRIED RICE (Non-Veg)",
        price: "12",
      },
      {
        title: "NOODEL CHICKEN BURGER",
        price: "12",
      },
    ],
  },
  {
    title: "KATHI ROLL",
    priceData: [
      {
        title: "BUTTER CHICKEN ROLL",
        price: "10",
      },
      {
        title: "TANDOORI CHICKEN ROLL",
        price: "10",
      },
      {
        title: "PANNER MASALA ROLL",
        price: "10",
      },
    ],
  },
  {
    title: "RICE & BREAD",
    priceData: [
      {
        title: "BASMATI RICE",
        price: "5",
      },
      {
        title: "JEERA RICE",
        price: "5",
      },
      {
        title: "COCONUT RICE",
        price: "6",
      },
      {
        title: "SAFFRON RICE",
        price: "6",
      },
      {
        title: "TAVA ROTI",
        price: "2.50",
      },
      {
        title: "LACHHA PARATHA",
        price: "4",
      },
      {
        title: "ALOO PARATHA",
        price: "5",
      },
    ],
  },
];

export const appetizerPriceListData = [
  {
    title: "APPETIZERS",
    priceData: [
      {
        title: "VEG SAMOSA (2 Pcs)",
        price: "4",
      },
      {
        title: "CHICKEN SAMOSA (2 Pcs)",
        price: "5",
      },
      {
        title: "PAPPRI CHAAT/ALOO CHAAT/SAMOSA CHAAT",
        price: "9",
      },
      {
        title: "ALOO TIKKI",
        price: "5",
      },
      {
        title: "ALOO TIKKI BURGER",
        price: "11",
      },
      {
        title: "VEG PAKORA",
        price: "9",
      },
      {
        title: "PANEER PAKORA",
        price: "12",
      },
      {
        title: "FISH PAKORA",
        price: "14",
      },
    ],
  },
  {
    title: "TANDOOR",
    priceData: [
      {
        title: "CHICKEN TIKKA",
        price: "15",
      },
      {
        title: "MALAI CHICKEN TIKKA",
        price: "15",
      },
      {
        title: "TANDOORI CHICKEN 1/2",
        price: "15",
      },
      {
        title: "FISH TIKKA",
        price: "15",
      },
      {
        title: "PANEER TIKKA",
        price: "15",
      },
      {
        title: "TANDOORI SOYA CHAAP MASALA",
        price: "16",
      },
      {
        title: "NON-VEG PLATTER",
        price: "25",
      },
    ],
  },
  {
    title: "BIRYANI",
    priceData: [
      {
        title: "VEG BIRYANI",
        price: "13",
      },
      {
        title: "CHICKEN BIRYANI",
        price: "16",
      },
      {
        title: "BEEF BIRYANI",
        price: "16",
      },
      {
        title: "LAMB BIRYANI",
        price: "17",
      },
      {
        title: "GOAT BIRYANI",
        price: "18",
      },
      {
        title: "SHRIMP BIRYANI",
        price: "18",
      },
    ],
  },
  {
    title: "BILLOO'S SIGNATURE CHOLEY BHATUREY",
    priceData: [
      {
        title: "CHOLEY BHATUREY",
        price: "12",
      },
      {
        title: "POORI CHOOLEY",
        price: "12",
      },
      {
        title: "EXTRA BHATUREY/ POORI",
        price: "3",
      },
      {
        title: "EXTRA CHOLEY",
        price: "6",
      },
      {
        title: "HALVA",
        price: "6",
      },
    ],
  },
  {
    title: "KIDS SPECIAL",
    priceData: [
      {
        title: "VEGETABLE MAGGI",
        price: "7",
      },
      {
        title: "CHICKEN NUGGET WT FRIES",
        price: "9",
      },
    ],
  },
];
